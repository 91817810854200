// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-course-1-js": () => import("./../../../src/pages/course1.js" /* webpackChunkName: "component---src-pages-course-1-js" */),
  "component---src-pages-course-2-js": () => import("./../../../src/pages/course2.js" /* webpackChunkName: "component---src-pages-course-2-js" */),
  "component---src-pages-course-3-js": () => import("./../../../src/pages/course3.js" /* webpackChunkName: "component---src-pages-course-3-js" */),
  "component---src-pages-detail-1-js": () => import("./../../../src/pages/detail1.js" /* webpackChunkName: "component---src-pages-detail-1-js" */),
  "component---src-pages-detail-2-js": () => import("./../../../src/pages/detail2.js" /* webpackChunkName: "component---src-pages-detail-2-js" */),
  "component---src-pages-detail-3-js": () => import("./../../../src/pages/detail3.js" /* webpackChunkName: "component---src-pages-detail-3-js" */),
  "component---src-pages-detail-4-js": () => import("./../../../src/pages/detail4.js" /* webpackChunkName: "component---src-pages-detail-4-js" */),
  "component---src-pages-diagnocy-js": () => import("./../../../src/pages/diagnocy.js" /* webpackChunkName: "component---src-pages-diagnocy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-members-js": () => import("./../../../src/pages/members.js" /* webpackChunkName: "component---src-pages-members-js" */),
  "component---src-pages-qanda-js": () => import("./../../../src/pages/qanda.js" /* webpackChunkName: "component---src-pages-qanda-js" */),
  "component---src-pages-service-js": () => import("./../../../src/pages/service.js" /* webpackChunkName: "component---src-pages-service-js" */),
  "component---src-pages-specialmenu-js": () => import("./../../../src/pages/specialmenu.js" /* webpackChunkName: "component---src-pages-specialmenu-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

